import React from 'react'
import copyright from 'copyright'
import Icon from 'components/Icon'
import LinkList from 'components/LinkList'
import { Container } from 'modules/marketplace/layout/components/ResponsiveContainers'
import styles from './Footer.module.css'

const socials = [
  {
    link: 'https://www.facebook.com/fieldfolio/',
    icon: 'facebook'
  },
  {
    link: 'https://www.instagram.com/fieldfolio/',
    icon: 'instagram'
  },
  {
    link: 'https://www.twitter.com/fieldfolio/',
    icon: 'twitter'
  },
  {
    link: 'https://www.pinterest.com.au/fieldfolio/',
    icon: 'pinterest'
  },
  {
    link: 'https://www.linkedin.com/company/fieldfolio/',
    icon: 'linkedin'
  }
]

const links = [
  {
    href: '/sitemap',
    label: 'Sitemap',
    isExternal: true
  },
  {
    href: '/terms',
    label: 'Terms',
    isExternal: true
  },
  {
    href: '/privacy',
    label: 'Privacy',
    isExternal: true
  },
  {
    href: '/contact',
    label: 'Contact'
  },
  {
    href: String(process.env.NEXT_PUBLIC_HELP_URL),
    label: 'Help',
    isExternal: true
  }
]

const Footer = () => (
  <footer className={styles.footer}>
    <Container className={styles.container}>
      <section className={styles.section}>
        <ul className={styles.socialLinkList}>
          {socials.map(s => (
            <li key={s.link}>
              <a href={s.link} target="_blank" rel="noopener noreferrer">
                <Icon kind={s.icon} size={18} />
              </a>
            </li>
          ))}
        </ul>

        <small>{copyright({ name: '������̳', short: true })}</small>
      </section>

      <section className={styles.section}>
        <LinkList className={styles.legalLinklist} links={links} />
      </section>
    </Container>
  </footer>
)

export default Footer
